<template>
  <section>
    <div style="display:flex;" >
        <!-- <el-switch
          style="margin-top:10px;margin-left:10px;width:220px;"
          v-model="reviewSwitch"
          inactive-color="#13ce66"
          active-color="#ff4949"
          active-text="我回复的"
          @change="handleReviewSwitchChange"
          inactive-text="我发表的">
        </el-switch> -->

        <el-select v-model="selectVal" style="margin-left:2px;margin-top:3px;" size="small" placeholder="请选择" @change="handleSelectChange">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!--工具条-->
        <toolbar :buttonList="buttonList"
                @callFunction="callFunction"
                :buttonListmsg="buttonListmsg"></toolbar>
    </div>
    <!--列表-->
    <el-table :data="users"
              ref="multipleTable"
              highlight-current-row
              lazy
              :indent="30"
              :load="load"
              :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
              :fit="true"
              :cell-style="mycellStyle"
              :header-cell-style="{background:'#dce4f1',color:'#000000'}"
              @current-change="selectCurrentRow"
              @row-dblclick="checkInfo"
              @cell-mouse-enter="tabmouseEnter"
              @cell-mouse-leave="tabmouseLeave"
              style="width: 100%;">
      <!-- <el-table-column type="index"></el-table-column> -->
      <el-table-column type="index" width="10">
        <template scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="工作项目"
                       prop="WorkItem"
                       min-width="350px">
        <template slot-scope="scope">

                
          <!-- <i class="el-icon-timer"
             v-if="scope.row.IsHasChildren == true"></i>
          <i class="el-icon-timer"
             v-if="localuser != scope.row.PersonOfDuty && scope.row.IsHasChildren == false"></i>
              -->
              
            <span :style="{'cursor':'default','display':'block','padding-left':'20px','margin-top':scope.row.IsHasChildren?'-22px':'0px','margin-bottom':scope.row.IsHasChildren ?'0px':'15px','margin-left': scope.row.MarginLeft+'px' }">{{scope.row.Number}}、{{scope.row.WorkItem}}
              <!-- <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button> -->
            </span>
              <!-- <span :style="{'cursor':'default'}">{{scope.row.WorkItem}}</span> -->
                <!-- <span v-if="!scope.row.WorkDescription" ><el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</span>
                <el-button v-else style="color:#606266;font-weight:normal;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button> -->
                <!-- <el-tooltip v-else placement="right-start" effect="light" :enterable="true">
                  <div slot="content" v-html="'<span style=\'color:#999\' >工作项目:</span><br/>'+scope.row.WorkItem+ '<br/><span style=\'color:#999\' >工作描述:</span><br/>'+scope.row.WorkDescription.replace(/\n/g,'<br/>')+ '<br/><span style=\'color:#999\' >创建于:</span><br/>'+scope.row.CreateTime+ '<br/><span style=\'color:#999\' >计划完成时间:</span><br/>'+scope.row.PlanComplateTime+ '<br/><span style=\'color:#999\' >进度:</span><br/>'+formatProgress(scope.row)+ '<br/><span style=\'color:#999\' >最新进度:</span><br/>'+(scope.row.LastProgressDescription==null?'':scope.row.LastProgressDescription.replace(/\n/g,'<br/>'))" ></div>
                  <el-button style="color:black;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button>
                </el-tooltip> -->
        </template>
      </el-table-column>
      <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
      <!-- <el-table-column label="计划完成时间"
                       prop="PlanComplateTime"
                       min-width="115px"
                       :formatter="formatPlanComplateTime">
        <template slot-scope="scope">
          <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
            <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
            &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
          </div>
          <div v-else>
            <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
            &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="任务状态"
                       prop="Status"
                       align="center"
                       min-width="100px">
        <template slot-scope="scope">
            <div>
              <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
              <el-tag v-if="scope.row.Status == 0"
                      effect="dark"> 进行中</el-tag>
              <el-tag v-if="scope.row.Status == 2"
                      effect="dark"
                      type="success">已完成</el-tag>
              <el-tag v-if="scope.row.Status == -1"
                      effect="dark"
                      type="info">已取消</el-tag>
              <el-tag v-if="scope.row.Status == 3"
                      effect="dark"
                      type="danger">退回</el-tag>
              <el-tag v-if="scope.row.Status == 1"
                      effect="dark"
                      type="warning">已完成审核中</el-tag>
              <el-tag v-if="scope.row.Status == 4"
                      effect="dark"
                      type="warning">新建任务待审核</el-tag>
              <el-tag v-if="scope.row.Status == 5"
                      effect="dark"
                      type="danger">取消任务待审核</el-tag>
              <el-tag v-if="scope.row.Status == 6"
                      effect="dark"
                      type="danger">新建任务退回</el-tag>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="任务责任人"
                       prop="PersonOfDuty"
                       min-width="75px"></el-table-column>
      <!-- <el-table-column label="进度"
                       prop="TaskProgressValue"
                       :formatter="formatProgress"
                       align="left"
                       min-width="55px"></el-table-column> -->
      <!-- <el-table-column label="任务类型"
                       prop="CommonTaskClassifyText"
                       min-width="100px"
                       :formatter="formatCommonTaskClassifyText"></el-table-column> -->
      <el-table-column label="操作" prop="ReplayID" align="cneter"
                       min-width="66px">
        <template slot-scope="scope">
            <div>
                <!-- <el-tag  :type="scope.row.ReplayID==null?'success':'primary'">{{scope.row.ReplayID==null?'我发表的':'我回复的'}}</el-tag> -->
                <el-tag  :type="scope.row.ReplayID==null?'success':'primary'">{{formatMsg(scope.row)}}</el-tag>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="评论内容" v-if="reviewContent" prop="ReviewContent" min-width="350px">
        <template slot-scope="scope">
            <div v-html="scope.row.ReviewContent"></div>
        </template>
      </el-table-column>
      <el-table-column label="评论时间" v-if="reviewTime"
                       prop="ReviewTime"
                       min-width="150px"></el-table-column>
      <el-table-column label="点赞人" v-if="!reviewTime"
                       prop="LikeName"
                       min-width="70px"></el-table-column>
      <el-table-column label="点赞时间" v-if="!reviewTime"
                       prop="LikeTime"
                       min-width="150px"></el-table-column>
      <el-table-column label="操作"
                       min-width="80px">
        <template slot-scope="scope">
          <p><el-link type="primary" :underline="false" @click="checkInfo(scope.row)" >查看</el-link></p>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>


    <el-dialog :visible.sync="chooseNewTimeVisiable"
               v-model="chooseNewTimeVisiable"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 60%;margin-left:21%;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">设置【{{this.currentRow==null?'':this.currentRow.WorkItem}}】新的计划完成日期</span>
      </template>
      <el-form label-width="85px"
               style="white-space:nowrap;">
          <el-form-item label="选择新日期:" prop="newPlanTime">
            <el-date-picker
                :picker-options="pickerOptions222"
                v-model="newPlanTime"
                type="date"
                value-format="yyyy-MM-dd"
                style="width:240px"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="submitProlong">确定</el-button>
      </div>
    </el-dialog>

    <!--审核界面-->
    <el-dialog :visible.sync="approvalVisible"
               v-model="approvalVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">审核</span>
      </template>
      <el-form :model="approvalForm"
               label-width="85px"
               ref="approvalForm"
               style="white-space:nowrap;"
               :rules="approvalFormRule">
        <el-form-item label="审核结果:"
                      prop="Status">
          <el-select style="width: 50%;"
                     v-model="approvalForm.Status"
                     placeholder="请选择审核结果">
            <el-option v-for="item in StatustModules"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核意见:"
                      prop="ApprovalContent">
          <el-col :span="22">
            <el-input v-model="approvalForm.ApprovalContent"
                      type="textarea"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--编辑任务界面-->
    <el-dialog :visible.sync="editFormVisible"
               v-model="editFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">编辑任务</span>
      </template>
      <el-form :model="editForm"
               label-width="85px"
               :rules="editFormRules"
               ref="editForm">
        <el-form-item label="任务类型:"
                      prop="CommonTaskClassifyText">
          <el-input v-model="CommonTaskClassifyText"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="工作项目:"
                      prop="WorkItem">
          <el-input v-model="editForm.WorkItem"
                    maxlength="30"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="工作描述:"
                      prop="WorkDescription">
          <el-input v-model="editForm.WorkDescription"
                    type="textarea"
                    maxlength="500"
                    show-word-limit></el-input>
        </el-form-item>
        <el-row style="height: 40px; margin-bottom: 20px;">
          <el-col :span="0.1">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2.5">
            <span>计划完成时间:</span>
          </el-col>
          <el-col :span="7">
            <el-date-picker :picker-options="pickerOptions0"
                            v-model="editForm.PlanComplateTime"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期"></el-date-picker>
          </el-col>
        </el-row>
        <el-form-item label="备注:"
                      prop="Remark">
          <el-input v-model="editForm.Remark"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>


    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>



    <!--工作任务添加并指派-->
    <el-dialog :visible.sync="addAssignFormVisible"
               v-model="addAssignFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">指派任务</span>
      </template>
      <el-form label-width="85px"
               :model="addForm"
               style="white-space:nowrap;">
        <el-row style="height: 50px;">
          <el-col :span="0.1">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2">
            <span>被指派人:</span>
          </el-col>
          <el-col :span="6">
            <el-input v-model="addForm.AssignName"
                      readonly
                      placeholder="请选择被指派人">{{needName}}</el-input>
            <el-button type="text"
                       size="mini"
                       @click="handleChooseAssUser">选择人员</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="完成后需我审核:"
                          prop="IsNeedApproval">
              <el-switch v-model="addForm.IsNeedApproval"
                         style="left: 30px;"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="height: 60px;">
          <el-col :span="0.1">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2.5">
            <span>计划完成时间:</span>
          </el-col>
          <el-col :span="7">
            <el-date-picker :picker-options="pickerOptions0"
                            v-model="addForm.PlanComplateTime2"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期"></el-date-picker>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="23">
            <el-form-item label="工作项目:"
                          prop="WorkItem2">
              <el-input v-model="addForm.WorkItem2"
                        maxlength="30"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="23">
            <el-form-item label="工作描述:"
                          prop="WorkDescription2">
              <el-input v-model="addForm.WorkDescription2"
                        type="textarea"
                        maxlength="500"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="assignaddSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--关联年度计划-->
    <el-dialog :visible.sync="NiandujihuaVisible"
               v-model="NiandujihuaVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">关联年度计划</span>
      </template>
      <el-form :model="yearplanForm"
               ref="yearplanForm"
               label-width="85px"
               :rules="yearplanFormRules">
        <el-form-item label="年度计划"
                      prop="YearPlan">
          <el-select v-model="yearplanForm.YearPlan"
                     @change="YearPlanchange">
            <el-option v-for="item in YearPlanTaskList"
                       :key="item.Id"
                       :value="`${item.YearPlanId}|${item.TaskId}|${item.WorkItem}`"
                       :label="item.WorkItem">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="yearplanSubmit">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择人员"
               :visible.sync="addUserVisible"
               v-model="addUserVisible"
               width="75%"
               center
               append-to-body>
      <UserChoose :data="choosedusers"
                  :all="true"
                  :single="false"
                  @callback="chooseUserCallBack"></UserChoose>
    </el-dialog>

  </section>
</template>
<script>
import util from '../../../util/date';
import { Loading } from 'element-ui'
import { AddTaskCollect, RemoveTaskCollect, AddTaskProgressReview,QueryReviewByProgressId,ApproveTaskByTaskId, QueryPageReviewTasksByUserCode, QueryPageLikeTasksByUserCode, AddTaskProgress, QueryTaskProgressByTaskId, DeleteTaskProgress, GetUserPostList, AssignTask,ProlongPlanComplateTime, CancelTask, QueryChildTasksByParentId, UpdateTaskContent, QueryTasksById, GetYearPlanListByUserCode, SetTaskAboutYearPlan } from '../../api/oa';
import taskinfo from "../../components/taskinfo.vue"
import Toolbar from "../../components/Toolbar";
import UserChoose from "../../components/UserChoose";
import { getButtonList } from "../../promissionRouter";
export default {
  components: { taskinfo, Toolbar, UserChoose },
  data () {
    return {
      reviewSwitch:false,
      currentActiveId: null,
      loading: '',
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      localuser: null,
      users: [],
      tasks: [],
      progresscheck: null,      //进行完成进度验证
      buttonList: [],
      buttonList2: [],
      buttonListmsg2: 'hidde',
      CommonTaskClassifyList: [],    //任务类型
      istaskpro: true,
      pValue1: null,   //进度条数值
      pValue2: null,   //进度条数值
      customColor: '#6f7ad3',   //进度条颜色
      buttonListmsg: 'taskmanage',
      needName: null,
      maps: new Map(),
      currentRow: null,
      proRow: null,
      showtoolbar: true,
      showminus: false,
      showplus: true,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      CommonTaskClassifyText: null,
      currentProgressRowIndex: null,
      assignpeo: 'assignpeo',
      selectOptions: [{
        value: 1,
        label: '我发表的评论'
      }, {
        value: 2,
        label: '我回复的评论'
      }, {
        value: 3,
        label: '我的点赞'
      }, {
        value: 4,
        label: '收到点赞'
      }],
      filters: {
        name: ''
      },
      StatustModules: [
        {
          value: 1,
          label: "审核通过"
        },
        {
          value: -1,
          label: "退回"
        }
      ],
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      pickerOptions222: {
      },
      newPlanTime:'',
      selectVal:1,
      chooseNewTimeVisiable:false,
      approvalVisible: false,   //审核界面是否显示
      checkVisible: false,
      addProgressVisible: false,
      addAssignFormVisible: false,
      userid: [], //暂存选中角色下所有用户的id
      choosedusers: [],//抄送人
      addUserVisible: false,
      editFormVisible: false,
      approvalFormRule: {
        Status: [
          { required: true, message: '请选择审核结果', trigger: 'blur' }
        ]
      },
      approvalForm: {
        TaskId: null,
        Status: null,
        CreatedId: null,
        CreatedBy: null,
        ApprovalContent: null
      },
      checkForm: {            //查看表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null

      },
      addProgressFormRules: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      addForm: {
        AssignName: null,
        AssignCode: null,
        WorkItem2: null,
        WorkDescription2: null,
        PlanComplateTime2: null
      },
      editForm: {              //编辑表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
      },
      editFormRules: {
        WorkItem: [
          { required: true, message: '请填写工作项目', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请输入工作描述', trigger: 'blur' }
        ]
      },
      yearplanFormRules: {
        YearPlan: [
          { required: true, message: '请选择需关联的年度计划', trigger: ["blur", 'change'] }
        ]
      },
      YearPlanTaskList: [],       //年度计划列表
      NiandujihuaVisible: false,
      reviewContent:true,
      reviewTime:true,
      yearplanForm: {
        YearPlan: null,
        YearplanId: null,
        TaskId: null
      },
      fileList: [],
      operation1: true,
      key: 0,
      content:'',
      mapReview:new Map(),
      collectBtn:false,
      collectRowId:'',
      mapLevel:new Map()
    }
  },
  methods: {
    formatMsg(row)
    {
      if(this.selectVal==1||this.selectVal==2)
      {
        return row.ReplayID==null?'发表评论':'回复评论';
      }else{
        return '点赞';
      }
    },
    mycellStyle(row,column,rowIndex,columnIndex)
    {
      // return 'background:red'
      // console.log(row);
      if(row.row.Level===1)
      {
        return 'background:#fff'
      }
      else if(row.row.Level===2)
      {
        return 'background:#e9f3ff'
      }
      else if(row.row.Level===3)
      {
        return 'background:#d4e9ff';
      }
      else if(row.row.Level===4)
      {
        return 'background:#bfdeff';
      }
      else if(row.row.Level===5)
      {
        return 'background:#aad2ff';
      }
      else if(row.row.Level===6)
      {
        return 'background:#96c8ff';
      }
      else if(row.row.Level===7)
      {
        return 'background:#81bdff';
      }
      else if(row.row.Level===8)
      {
        return 'background:#6eb3ff';
      }
      else if(row.row.Level===9)
      {
        return 'background:#5ba8ff';
      }
      else if(row.row.Level===10)
      {
        return 'background:#499eff';
      }
      return ''
    },
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      if(column.property!='WorkItem')return;
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    handleProcessInput (val) {
      if (this.pValue2 < this.pValue1) {
        this.pValue2 = this.pValue1;
      }
      if (this.progresscheck == true) {
        if (this.pValue2 >= 100) {
          if (this.checkForm.IsNeedApproval == false) {
            this.$message({
              message: '任务已完成，请提交！',
              type: 'success'
            });
          } else {
            this.$message({
              message: '任务已完成，请提交审核！',
              type: 'warning'
            });
          }
          this.pValue2 = 100;
        }
      } else {
        if (this.pValue2 > 95) {
          this.$message({
            message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            type: "warning",
            duration: 10000
          });
          this.pValue2 = 95;
        }
      }
    },
    handleChooseAssUser () {
      this.addUserVisible = true;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack (data) {
      // console.log(data);
      var newdata = data.map(function (item) {
        return item["Id"];
      });
      var namenewdata = data.map(function (item) {
        return item["UserNameClaim"];
      });
      // console.log(newdata);

      var str = "";
      for (var i = 0; i < namenewdata.length; i++) {
        str += namenewdata[i] + ",";
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }

      var idstr = "";
      for (var i = 0; i < newdata.length; i++) {
        idstr += newdata[i] + ",";
      }
      if (idstr.length > 0) {
        idstr = idstr.substr(0, idstr.length - 1);
      }
      this.needName = str;
      this.addForm.AssignCode = idstr;
      this.addForm.AssignName = str;
      this.addUserVisible = false;
    },
    dealFiles (data) {
      // console.log(data.backData);
      if (data.backData.length > 0) {
        this.addForm.FileArry = data.backData;
        this.addForm.FileName = data.backData[0].FileName;
        this.addForm.FileAddress = data.backData[0].URL;
      }
    },
    dealFiles2 (data) {
      if (data.backData.length > 0) {
        this.addProgressForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ',';
          fileaddress += element.URL;
          fileaddress += ',';
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    deleteFiles2 (data) {
      if (data.backData.length >= 0) {
        this.addProgressForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    selectProRow (val) {
      this.proRow = val;
    },
    taskProgressRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },
    onRowClick (row, event, column) {
      //行点击消除new标记
      this.currentProgressRowIndex = row.index;
    },
    formatStatus: function (row, column) {
      return row.Status == 0 ? '进行中' : row.Status == 1 ? '审批中' : row.Status == 2 ? '已完成' : row.Status == -1 ? '已取消' : row.Status == 3 ? '退回' : '未知';
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatProgress: function (row, col) {
      return row.TaskProgressValue + "%";
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    handleSelectChange(val)
    {
      setTimeout(() => {
        this.getTasks();
      }, 330);
    },
    handleReviewSwitchChange(val)
    {
      setTimeout(() => {
        this.getTasks();
      }, 330);
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getTasks();
    },
    rowformat(element)
    {
      var tag = element.TagType<=1?element.Tag:'';
      var has = this.mapLevel.has(tag)
      // var has = this.mapLevel.has(element.Tag)
      var parentLevel = 0;
      if(has)
      {
        parentLevel = this.mapLevel.get(tag);
        // parentLevel = this.mapLevel.get(element.Tag);
      }
      else
      {
        parentLevel = this.mapLevel.get(element.ParentId);
      }
      var level = parentLevel+1;
      if(level==2)
      {
        element.MarginLeft = 30;
      }else if(level == 3){
        element.MarginLeft = 60;
      }else if(level == 4){
        element.MarginLeft = 90;
      }else if(level == 5){
        element.MarginLeft = 120;
      }else if(level == 6){
        element.MarginLeft = 150;
      }else if(level == 7){
        element.MarginLeft = 180;
      }else if(level == 8){
        element.MarginLeft = 210;
      }else if(level == 9){
        element.MarginLeft = 240;
      }else if(level == 10){
        element.MarginLeft = 270;
      }
      element.Level = level;
      this.mapLevel.set(element.Id,level);
      return element;
    },
    load (tree, treeNode, resolve) {
      // 将当前选中节点数据存储到map中
      this.maps.set(tree.Id, { tree, treeNode, resolve })
      let para = {
        taskId: tree.Id,
      };
      QueryChildTasksByParentId(para).then((res) => {
        var myarr = new Array();
        var number = 0;
        res.data.response.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            myarr.push(element)
        })
        resolve(myarr);
        // resolve(res.data.response)
      });
    },
    // 重新触发树形表格的loadTree函数
    refreshLoadTree (parentId) {
      // 根据父级id取出对应节点数据
      // const { tree, treeNode, resolve } = this.maps.get(parentId)
      // this.$set(this.$refs.multipleTable.store.states.lazyTreeNodeMap, parentId, [])
      // if (tree) {
      //   this.load(tree, treeNode, resolve)
      // }
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    getTasks () {
      var user = JSON.parse(window.localStorage.user);
      let self = this;
      if(self.selectVal==1 || self.selectVal==2)
      {
        self.reviewContent=true;
        self.reviewTime=true;
        let para = {
          type: this.selectVal,
          userCode: user.sub,
          pageIndex: this.page,
          pageSize: this.pageSize,
          queryKey: this.filters.name
        };
        QueryPageReviewTasksByUserCode(para).then((res) => {
          this.total = res.data.response.pageCount;
          this.totaldata = res.data.response.dataCount;
          var arr=new Array();
          var number = (this.page-1)*this.pageSize;
          res.data.response.data.forEach(element => {
            // console.log(element);
            element.ReviewContent = element.ReviewContent.replace('<p>','').replace('</p>','').replace('<br/>','');
            element.Number=++number;
            element.MarginLeft=3;
            element.Level = 1;
            this.mapLevel.set(element.Id,1);
            arr.push(element)
          });
          this.users = arr;
          // this.users = res.data.response.data;
        });
      }else{
        self.reviewContent=false;
        self.reviewTime=false;
        let para = {
          type: this.selectVal==3?1:2,
          userCode: user.sub,
          pageIndex: this.page,
          pageSize: this.pageSize,
          queryKey: this.filters.name
        };
        QueryPageLikeTasksByUserCode(para).then((res) => {
          this.total = res.data.response.pageCount;
          this.totaldata = res.data.response.dataCount;
          var arr=new Array();
          var number = (this.page-1)*this.pageSize;
          res.data.response.data.forEach(element => {
            // console.log(element);
            // element.ReviewContent = element.ReviewContent.replace('<p>','').replace('</p>','').replace('<br/>','');
            element.Number=++number;
            element.MarginLeft=3;
            element.Level = 1;
            this.mapLevel.set(element.Id,1);
            arr.push(element)
          });
          this.users = arr;
        });
      }
    },
    //审核
    checkApproval () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要审核的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      if (row.ParentId != null && ucode.sub == row.PersonOfDutyCode) {
        this.$message({
          message: "当前任务需上级责任人审核!",
          type: "error",
          duration: 10000
        });
        return;
      }
      if (row.IsNeedApproval == true) {
        if (row.Status == 1) {
          this.approvalVisible = true;
          this.approvalForm.TaskId = row.Id;
          this.approvalForm.CreatedId = row.CreatedId;
          this.approvalForm.CreatedBy = row.CreatedBy;
        } else {
          this.$message({
            message: "当前任务未完成,不能审核!",
            type: "error",
            duration: 10000
          });
        }
      } else {
        this.$message({
          message: "当前任务无需审核!",
          type: "error",
          duration: 10000
        });
      }
    },
    //审核提交
    addSubmit () {
      this.$refs.approvalForm.validate((valid) => {
        if (valid) {
          let para = Object.assign({}, this.approvalForm);
          if (this.approvalForm.Status == null) {
            this.$message({
              message: "请选择审核结果！",
              type: "error"
            });
            return;
          }
          this.Loadingstart();
          ApproveTaskByTaskId(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.Loadignend();
              this.$refs['approvalForm'].resetFields();
              this.approvalVisible = false;
              this.getTasks();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        }
      });
    },
    //编辑任务
    handleEdit () {
      let row = this.currentRow;
      var ucode = JSON.parse(window.localStorage.user);
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      } else if (ucode.name != row.PersonOfDuty) {
        this.$message({
          message: "非该任务责任人,不能编辑！",
          type: "error",
          duration: 10000
        });
        return;
      }
      else if (row.TaskProgressValue != 0) {
        this.$message({
          message: '当前任务已开始进行，不能编辑',
          type: 'error',
          duration: 10000
        });
      } else {
        if (row.TaskClassify == 1) {
          this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        } else {
          if (row.TaskClassify == 2) {
            this.CommonTaskClassifyText = "跨部门协调";
          }
          else if (row.TaskClassify == 3) {
            this.CommonTaskClassifyText = "例会项目";
          } else if (row.TaskClassify == 4) {
            this.CommonTaskClassifyText = "年度计划";
          } else if (row.TaskClassify == 5) {
            this.CommonTaskClassifyText = "专项会议";
          }
          else {
            this.CommonTaskClassifyText = "其他";
          }
        }
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
      }
    },
    //编辑提交
    editSubmit () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.Loadingstart();
          let para = Object.assign({}, this.editForm);
          UpdateTaskContent(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.Loadignend();
              this.$refs['editForm'].resetFields();
              this.currentRow = null;
              this.editFormVisible = false;
              this.getTasks();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        }
      });
    },
    //双击查看详细信息
    checkInfo (row) {
      this.checkVisible = true;
      this.checkForm = row;
      return;
      if (row.TaskClassify == 1) {
        this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.Id, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        this.checkForm.Files = [];
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      this.pValue1 = row.TaskProgressValue;
      this.pValue2 = row.TaskProgressValue;
      this.progresscheck = row.ChildrenProgressComplated;
      var ucode = JSON.parse(window.localStorage.user);
      if (ucode.sub != row.PersonOfDutyCode || row.Status == 5) {
        this.istaskpro = false;
      } else {
        if (this.pValue1 < 100) {
          if (this.pValue1 == 95 && this.progresscheck == false) {
            this.istaskpro = false;
          } else {
            this.istaskpro = true;
          }
        } else {
          this.istaskpro = false;
        }
      }
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        var data = res.data.response;
        var formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("tasksdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
    },
    //进度新增
    addpro () {
      this.addProgressVisible = true;
      this.addProgressForm.FileName=null;
      this.addProgressForm.FileAddress=null;
      this.addProgressForm.ProgressDescription = null;
      this.showminus = false;
      this.showplus = true;
    },
    addopen () {
      if (this.progresscheck == false) {
        this.$message({
          message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
          type: "warning",
          duration: 10000
        });
      }
    },
    proclose () {
      this.addProgressVisible = false;
      this.addProgressForm.ProgressDescription = null;
      this.addProgressForm.FileName=null;
      this.addProgressForm.FileAddress=null;
      this.pValue2 = this.pValue1;
      this.key = this.key + 1;
    },
    tipMessage () {
      this.$message({
        message: '填写未完成，请继续填写',
        type: 'warning'
      });
    },
    //进度新增提交
    checkSubmit () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        taskID: this.checkForm.Id,
        progressDescription: this.addProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        filename: this.addProgressForm.FileName,
        fileaddress: this.addProgressForm.FileAddress,
        createdId: user.sub,
        createdBy: user.name
      };
      if (this.addProgressForm.ProgressDescription == null) {
        this.$message({
          message: '请填写进度内容',
          type: 'warning'
        });
      } else {
        this.Loadingstart();
        AddTaskProgress(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.tasks = res.data.response;
              var data = res.data.response;
              let formatData = new Array();
              for (var a = 0; a < data.length; a++) {
                var element = data[a];
                element.Files = [];
                if (element.FileName != null) {
                  var arr = element.FileName.split(",");
                  var arr2 = element.FileAddress.split(",");
                  var files = [];
                  for (var d = 0; d < arr.length; d++) {
                    var f = {};
                    f.FileName = arr[d];
                    f.FileAddress = arr2[d];
                    files.push(f);
                  }
                  element.Files = files;
                }
                formatData.push(element);
              }
              this.tasks = formatData;
              this.RefreshProgress(this.tasks);
              this.$nextTick(function () {
                var div = document.getElementsByClassName("tasksdiv");
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight;
                }
              })
            });
            this.addProgressVisible = false;
            this.getTasks();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    },
    //删除选中进度
    deletepro () {
      // let row = this.proRow;
      if (!this.currentActiveId) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      }
      var length = this.tasks.length;
      if (this.currentProgressRowIndex < length - 1) {
        this.$message({
          message: "只允许删除最后一条进度",
          type: "error",
          duration: 10000
        });
        return;
      }
      this.$confirm('确认删除进度吗？', '提示', {}).then(() => {
        DeleteTaskProgress({ id: this.currentActiveId }).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.currentActiveId = null;
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.tasks = res.data.response;
              var data = res.data.response;
              let formatData = new Array();
              for (var a = 0; a < data.length; a++) {
                var element = data[a];
                element.Files = [];
                if (element.FileName != null) {
                  var arr = element.FileName.split(",");
                  var arr2 = element.FileAddress.split(",");
                  var files = [];
                  for (var d = 0; d < arr.length; d++) {
                    var f = {};
                    f.FileName = arr[d];
                    f.FileAddress = arr2[d];
                    files.push(f);
                  }
                  element.Files = files;
                }
                formatData.push(element);
              }
              this.tasks = formatData;
              this.RefreshProgress(this.tasks);
              this.$nextTick(function () {
                var div = document.getElementsByClassName("tasksdiv");
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight;
                }
              })
            });
            this.getTasks();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    //指派人回调
    assigncallFunction (newdata) {
      this.needName = null;
      this.addForm.AssignCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.AssignName = res.data.data[0].name;
          this.needName = this.addForm.AssignName;
        }
      });
    },
    //指派
    handleAssign () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要指派的一行数据！",
          type: "error"
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      if (ucode.sub != row.PersonOfDutyCode) {
        this.$message({
          message: '当前用户非任务责任人,不能指派！',
          type: 'error'
        });
        return;
      }
      this.addForm.AssignName = null;
      this.addForm.PlanComplateTime2 = null;
      this.addAssignFormVisible = true;
      this.addForm.WorkItem2 = row.WorkItem;
      this.addForm.WorkDescription2 = row.WorkDescription;
    },
    //指派提交
    assignaddSubmit () {
      let para = Object.assign({}, this.addForm);
      para.Id = this.currentRow.Id;
      para.CommonTaskClassify = 0;
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      if (para.AssignName == null || para.PlanComplateTime2 == null) {
        this.$message({
          message: '请填写完整表单',
          type: 'error'
        });
      } else {
        this.Loadingstart();
        AssignTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            this.addAssignFormVisible = false;
            this.addForm.AssignName = null;
            this.needName = null;
            this.$refs.multipleTable.toggleRowExpansion(this.currentRow, false);
            this.getTasks();
            this.refreshLoadTree(this.currentRow.Id);
            // window.location.reload();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    },
    //取消任务
    handleCancel () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要取消的一行数据！",
          type: "error"
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      this.$confirm('确认取消任务吗？', '提示', {}).then(() => {
        let para = {
          taskId: row.Id,
          wantCancelUserCode: ucode.sub,
          wantCancelUserName: ucode.name
        };
        CancelTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.currentRow = null;
            this.getTasks();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    RefreshProgress (tasksProgressRow) {
      var rowlength = tasksProgressRow.length;
      if (rowlength > 0) {
        var lastProgressValue = tasksProgressRow[tasksProgressRow.length - 1].ProgressValue;
        this.pValue2 = lastProgressValue;
        if (lastProgressValue >= 100) {
          this.istaskpro = false;
        }
      } else {
        this.pValue2 = 0;
      }
      this.pValue1 = this.pValue2;
      if (this.pValue1 == 95 && this.progresscheck == false) {
        this.istaskpro = false;
      } else {
        this.istaskpro = true;
      }
    },
    //关联年度计划
    YearPlanchange (val) {
      this.yearplanForm.YearplanId = val.split('|')[1];
    },
    submitProlong()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要延期的任务！",
          type: "warning"
        });
        return;
      }
      if(this.newPlanTime=='')
      {
        this.$message({
          message: "请选择新的计划完成日期",
          type: "warning"
        });
        return;
      }
      //var ucode = JSON.parse(window.localStorage.user);
      this.$confirm('确认要将任务延期吗？', '提示', {}).then(() => {
        let para = {
          taskId: row.Id,
          newdate: this.newPlanTime
        };
        ProlongPlanComplateTime(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.chooseNewTimeVisiable=false;
            this.newPlanTime='';
            this.currentRow = null;
            this.getTasks();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    handleProlong()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要延期的任务！",
          type: "warning"
        });
        return;
      }
      this.chooseNewTimeVisiable=true;
      //console.log(row);
      this.pickerOptions222 = Object.assign({},this.pickerOptions222,{
        disabledDate: (time) => {
          // return time.getTime() > row.PlanComplateTime
          return time.getTime() < new Date(row.PlanComplateTime);
        }
      })
    },
    yearplanchoose (row) {
      var user = JSON.parse(window.localStorage.user);
      if (row.PersonOfDutyCode == user.sub) {
        this.yearplanForm.TaskId = row.Id;
        this.NiandujihuaVisible = true;
      } else {
        this.$message({
          message: "非任务负责人无法进行关联操作",
          type: "warning"
        });
      }
    },
    //关联年度计划提交
    yearplanSubmit () {
      this.$refs.yearplanForm.validate(valid => {
        if (valid) {
          let para = { taskId: this.yearplanForm.TaskId, yearplanId: this.yearplanForm.YearplanId }
          this.$confirm("确定关联此年度计划吗？", "提示", {}).then(() => {
            SetTaskAboutYearPlan(para).then(res => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                });
                this.$refs.yearplanForm.resetFields();
                this.NiandujihuaVisible = false;
                this.getTasks();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              }
            })
          });
        }
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    }
  },
  mounted () {
    this.getTasks();
    var user = JSON.parse(window.localStorage.user);
    let para = { userCode: user.sub };
    GetYearPlanListByUserCode(para).then((res) => {
      this.YearPlanTaskList = res.data.response;
    });
    this.localuser = user.name;
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  }
}
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
input:disabled,
textarea:disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(12, 12, 12);
}
/deep/.el-icon-close:before {
  color: #ffffff;
}
.el-table .warning-row {
  background: #f0f8ff;
}

.approvediv,
.approvecard {
  height: calc(100vh - 120px);
}
.tasksdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>